<template>
  <v-col class="mx-4">
    <v-data-table
      :headers="headers"
      :items="items"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :loading="LOADING"
    >
      <!-- HEADER -->
      <template v-slot:top>
        <TableHeader :show-create="false" :show-search="false">
          <v-icon color="primary" class="mr-2">mdi-arrow-right-bold-hexagon-outline </v-icon>
          <span>{{ $t('import.title') }}</span>
        </TableHeader>
      </template>
      <template v-slot:item.status="{ item }">
        <v-icon v-if="item.status === true" color="light-green" small>mdi-check-circle </v-icon>
        <v-icon v-else color="red" small>mdi-alert-circle</v-icon>
      </template>
      <template v-slot:no-data>
        <span>{{ $t('import.noImports') }}</span>
      </template>
      <template v-slot:item.created="{ item }">
        <div>{{ item.created.toDate() | dateTime }}</div>
      </template>
    </v-data-table>
  </v-col>
</template>

<script>
import 'firebase/firestore';
import { db } from '@/plugins/firebase';
import { collection, getDocs, orderBy, query, limit } from 'firebase/firestore';
import TableHeader from '@/components/tableTemplates/TableHeader';

export default {
  name: 'Imports',
  components: {
    TableHeader
  },
  data() {
    return {
      search: '',
      headers: [
        { text: 'Status', value: 'status' },
        {
          text: 'Dateiname ',
          align: 'start',
          sortable: false,
          value: 'filename'
        },
        { text: 'Dateityp', value: 'fileType' },
        { text: 'Importzeit', value: 'created' }
      ],
      expanded: [],
      investments: [],
      itemsPerPage: [],
      items: [],
      currentPage: 1,
      isLoading: true,
      allItems: 0,
      singleExpand: false,
      sortBy: 'created',
      sortDesc: true
    };
  },

  created: function () {
    this.getItemsByPage();
  },
  methods: {
    async getItemsByPage() {
      this.LOADING = true;
      // const { page, itemsPerPage } = this.QUERY_OPTIONS;
      let docs;
      const page = 1;

      let querySnapshot;
      if (page === 1) {
        querySnapshot = await getDocs(
          query(collection(db, `investment-imports-${process.env.VUE_APP_ENV}`), orderBy('filename'), limit(40))
        );
      } else {
        console.log('unknown errors');
      }

      this.currentPage = page;

      const _items = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        _items.push({
          filename: data.filename,
          fileType: data.fileType,
          created: data.created,
          status: data.status
        });
      });
      this.items = _items;
      this.LOADING = false;
    },
    getAllItems() {
      getDocs(collection(db, `investment-imports-${process.env.VUE_APP_ENV}`)).then((querySnapshot) => {
        this.allItems = querySnapshot.size;
      });
    }
  }
};
</script>
